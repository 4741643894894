var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "我的知识",
        },
        on: {
          "head-upload": function ($event) {
            return _vm.headUpload()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "main-tab",
              attrs: { "tab-position": "left", type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { name: "myUpload" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "iconfont icon-shangchuan" }),
                    _vm._v(" 我的上传"),
                  ]),
                  _vm.activeName == "myUpload" ? _c("myUpload") : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { name: "myCollect" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "iconfont icon-shoucang" }),
                    _vm._v(" 我的收藏"),
                  ]),
                  _vm.activeName == "myCollect" ? _c("myCollect") : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { name: "myShare" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "iconfont icon-fenxiang" }),
                    _vm._v(" 我的分享"),
                  ]),
                  _vm.activeName == "myShare" ? _c("myShare") : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { name: "browsingHistory" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "iconfont icon-lishijilu" }),
                    _vm._v(" 浏览历史"),
                  ]),
                  _vm.activeName == "browsingHistory"
                    ? _c("browsingHistory")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }